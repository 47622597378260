:root {
  --primary: #1a947f;
  --secondary: #009d8c;
  --black: #0d0d0d;
  --white: #f9ffff;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.m-a {
  margin: auto;
}

h1 {
  color: var(--white);
  font-size: 45pt;
  margin: 0;
  padding: 45px;
  font-weight: 700;
}

h3 {
  color: var(--white);
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.App {
  text-align: center;
  background-color: #282c34;

  header {
    background-image: linear-gradient(
        0deg,
        rgba(13, 13, 13, 0.5),
        rgba(13, 13, 13, 0.5)
      ),
      url("./assets/images/banner_large.jpg");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    h1 {
      font-size: 72pt;
    }
  }

  .info {
    background-color: var(--secondary);
    padding: 7vh 10vw;

    .flex {
      display: flex;
      flex-wrap: wrap;
    }

    .block {
      width: 350px;
      height: 350px;
      position: relative;
      flex: 0 0 30%;
      margin: 25px;

      .image-container {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7px;
        }
      }

      .text-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #0d0d0db3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity ease-in-out 0.25s;
        border-radius: 7px;
        padding: 0 35px;

        &:hover {
          opacity: 1;
          transition: opacity ease-in-out 0.25s;
        }

        h3,
        p {
          color: var(--white);
          text-align: center;
        }
      }
    }
  }

  .contact {
    padding: 7vh 10vw;

    h1 {
      padding: 0 0 45px 0;
    }

    .flex {
      display: flex;
      justify-content: center;

      h3 {
        margin-bottom: 15px;
      }

      a {
        padding: 0 25px;
        text-align: center;
        color: var(--white);
        text-decoration: none;
        transition: color ease-in-out 0.25s;

        &:hover {
          color: var(--primary);
          transition: color ease-in-out 0.25s;
        }
      }
    }

    .map {
      padding-top: 45px;

      iframe {
        width: 100%;
        height: 50vh;
      }
    }
  }
}
